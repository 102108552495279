import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import AccountBox from '@material-ui/icons/AccountBox';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
      flexGrow: 1,
    },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.primary.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    display:"flex",
    justifyContent:"space-between",
    width: '100%',
  },
  loginLink: {
    color: "#fff",
    '&:hover': {
      color: "#fff",
    },
    '&:visited': {
      color: "#fff",
    }
  },
});

class NavBar extends Component {

  constructor(props){
    super(props);
    this.state = {
      anchorElement: null,
//      isProfileClicked: false,
      isLoggingIn: false,
    };
    this.handleMenuOpen       = this.handleMenuOpen.bind(this);
    this.handleMenuClose      = this.handleMenuClose.bind(this);
    this.handleLogout         = this.handleLogout.bind(this);
    this.handleProfileClicked = this.handleProfileClicked.bind(this);
//    console.log("NavBar.constructor: ", this.props);
  }

  handleMenuOpen(event: React.MouseEvent<HTMLElement>){
    this.setState({anchorElement: event.currentTarget});
  }

  handleMenuClose() {
    this.setState({anchorElement: null});
  }

  async handleLogout() {
    this.setState({isLoggingIn: true});
    this.handleMenuClose();
    const url = "/cgi-bin/logout.cgi";
    console.log(url, {"id": this.props.user.id});
    try {
      let res = await axios.post(url, {"id": this.props.user.id});
      console.log("SignIn.logUserIn result: ", res); 
     
      this.props.logout();
      this.props.history.push('/home');
      console.log("NavBar.handleLogout: ", this.props);
      
    }
    catch (error) {
      console.log(error);
    }
    this.setState({isLoggingIn: false});
  }

  handleProfileClicked(){
    this.handleMenuClose(); 
    this.props.history.push('/profile');
  }


  render() {
    const { classes } = this.props;

    return(
      <Container>
        <AppBar position="sticky" >
          <Toolbar className={classes.toolbar}>
            <div>
              <Link to="/home" href="/home" variant="body2">    
                <Avatar className={classes.avatar}>
                  <HomeIcon />
                </Avatar>
              </Link>
            </div>
            <div className={classes.grow} />
            {(this.props.user.id === ""
              ?
                <>
                  <Link to="/login" href="/login" variant="body2" className={classes.loginLink}>login</Link>
                  {" | "}
                  <Link to="/register" href="/register" variant="body2" className={classes.loginLink}>register</Link>
                </>
              : <>
                <div>
                  <Typography  variant="body1" display="inline">{this.props.user.name}</Typography>
                  <IconButton

                    aria-label="account of current user"
                    aria-controls="nav-account-menu"
                    aria-haspopup="true"
                    onClick={this.handleMenuOpen}
                    color="inherit">
                    <AccountBox />
                  </IconButton>
                </div>
                <div>
                  <Menu
                    id="nav-account-menu"
                    anchorEl={this.state.anchorElement}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    keepMounted
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={Boolean(this.state.anchorElement)}
                    onClose={this.handleMenuClose}>

                    <MenuItem onClick={this.handleProfileClicked}>Profile</MenuItem>
                    <Divider />
                    <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                  </Menu>    
                </div>
              </>
              )
            }
          </Toolbar>
        </AppBar>
        <Backdrop className={classes.backdrop} open={this.state.isLoggingIn}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    )
  }
}

export default withRouter(withStyles(styles)(NavBar));