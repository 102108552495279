import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';

class Home extends Component {
  
  
  
  renderHeader(){
    return (
      <Grid item xs={12}>
        <Typography variant="h5" color="primary" align="center">Action Match</Typography>  
      </Grid>
    )
  }
  
  renderSeason(){
    return (
      <Grid item xs={12} align="center" >
        <Card variant="outlined" raised={true} height="10%">
          <CardActionArea component={RouterLink} to="/season">
            <Typography color="primary" gutterBottom>
              Season
            </Typography>
          </CardActionArea>
        </Card>
      </Grid>
    )
  }
  
  renderCompetition(){
    return(
      <Grid item xs={12} align="center">
        <Card variant="outlined" raised={true} height="10%">
          <CardActionArea component={RouterLink} to="/competition">
            <Typography color="primary" gutterBottom>
              Competition Scores
            </Typography>
          </CardActionArea>
        </Card> 
      </Grid>
    )
  }
  
  renderMatch(){
    return (
      <Grid item xs={12} align="center">
        <Card variant="outlined" raised={true} height="10%">
          <CardActionArea component={RouterLink} to="/startmatch">
            <Typography color="primary" gutterBottom>
              Start a Match
            </Typography>
          </CardActionArea>
        </Card> 
      </Grid>
    )
  }
  
  render () {
    //{this.renderSeason()}
    return (
      <Container maxWidth="md">
        <Grid container justify="center" alignItems="center" spacing={2}>
          {this.renderHeader()}
          {this.renderCompetition()}
          {this.renderMatch()}        
        </Grid> 
      </Container>
    );
  }
}    
export default withRouter(Home);