import React, { Component } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TargetIcon from '../../../assets/images/target-icon-512.png';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
/*import { withStyles } from '@material-ui/core/styles';

const myStyles = (theme) => ({
  
  paper: {
    height: 20,
    width: 20,
  },
  control: {
    padding: theme.spacing(2),
  },
  target: {
    width: 10,
    height: 10,
  }, 
});*/


class MoverScorecard extends Component {
      
  constructor(props){
    super(props);
    this.state = {
      competition: props.competition,
      competitor:  props.competitor,
      division:    props.division,
      grade:       props.grade,
      
      scores: [
        {
          targetNumber: "target1", 
          Xs: 0, 
          eights: 0,
          tens: 0, 
          fives: 0, 
          zeros: 0, 
          penalties: 0,
        },
        {
          targetNumber: "target2", 
          Xs: 0, 
          eights: 0,
          tens: 0, 
          fives: 0, 
          zeros: 0, 
          penalties: 0,
        },
        {
          targetNumber: "target3", 
          Xs: 0, 
          eights: 0,
          tens: 0, 
          fives: 0, 
          zeros: 0, 
          penalties: 0,
        },
        {
          targetNumber: "target4", 
          Xs: 0, 
          eights: 0,
          tens: 0, 
          fives: 0, 
          zeros: 0, 
          penalties: 0,
        },
      ],
      points: {
          Xs: 0, 
          eights: 0,
          tens: 0, 
          fives: 0, 
          zeros: 0, 
          penalties: 0,
      }, 
      totalScore: 0,
      totalXs: 0,
      
      submitDisabled: true,
      scoreSubmitted: false,
    };
    this.submitScore = this.submitScore.bind(this);
  }
    
  async submitScore(e) {
    this.setState({submitDisabled: true});
    const uriParams = 
          "competitionid=" + this.state.competition.id +  
          "&competitorid=" + this.state.competitor.id +
          "&matchtype=mover" +
          "&score="+this.state.totalScore + 
          "&xs=" + this.state.totalXs +
          "&scorer=" + this.props.scorer.id +
          "&division=" + this.state.competitor.division;
    try {
      let res = await axios.get(`/cgi-bin/matchscore.cgi?` + uriParams);
      console.log("submit ok: ", res);
      this.setState({scoreSubmitted: true});
    }      
    catch(error){
      console.error("submit error: " , error);
      this.setState({scoreSubmitted: false});
    }      
  };

  handleKeyDown = (e) => {
    if (e.type === 'blur' || e.key === 'Enter' || e.key === 'Tab') {
      const str = e.target.id.split('.');
      let oldScores = this.state.scores;
      let oldPoints = this.state.points;
            
      //when data in a cell is deleted, avoid processing space or blank cells since they produce NaN by defaulting hte score update to zero. If the score is a number then use it
      let thisScore = 0;        
      if(Number.isInteger(parseInt(e.target.value,10))){ 
        thisScore = parseInt(e.target.value,10);
      }
        oldScores.map((score, i) => {
          if(score.targetNumber===str[0]){
          switch(str[1]) {
            case 'Xs': 
                score.Xs = thisScore;         
                break;
            case 'tens': 
                score.tens = thisScore;
                break;
            case 'eights': 
                score.eights = thisScore;
                break;
            case 'fives': 
                score.fives = thisScore;
                break;
            case 'zeros': 
                score.zeros = thisScore;
                break;
            case 'penalties':
                score.penalties = thisScore;
                break;
            default:
           }        
          }
          return score; 
        });
      
      let tmp = oldScores.reduce((totalscore, score) => totalscore + parseInt(score.Xs,10),0);
      oldPoints.Xs = tmp*10;
      //add up how many shots taken in total before multiploying shots by score per shot
      let shots = tmp;
      tmp = oldScores.reduce((totalscore, score) => totalscore + parseInt(score.tens,10),0);
      oldPoints.tens = tmp*10;
      shots += tmp;
      tmp = oldScores.reduce((totalscore, score) => totalscore + parseInt(score.eights,10),0);
      oldPoints.eights = tmp*8;
      shots += tmp;
      tmp = oldScores.reduce((totalscore, score) => totalscore + parseInt(score.fives,10),0);
      oldPoints.fives = tmp*5;
      shots += tmp;
      tmp = oldScores.reduce((totalscore, score) => totalscore + parseInt(score.zeros,10),0)
      oldPoints.zeros = tmp*0;
      shots += tmp;
      tmp = oldScores.reduce((totalscore, score) => totalscore + parseInt(score.penalties,10),0) ;
      oldPoints.penalties = tmp*10;
      shots += tmp;
      
      //only allow a score to be submitted when all shots are accounted for
      console.log("shots: " + shots);    
      if(shots === 48) {
        this.setState({submitDisabled: false}); 
        
      } else {
        this.setState({submitDisabled: true});
      }
      
      //update state with current scores and total points
      this.setState({scores: oldScores});
      this.setState({points: oldPoints});        
        
      //calculate and store the total score so far
      this.setState({totalScore: oldPoints.Xs + oldPoints.tens + oldPoints.eights + oldPoints.fives + oldPoints.zeros - oldPoints.penalties});

      //tally and store the total number of bullseyes/Xs so far
      this.setState({totalXs: oldScores.reduce((totalscore, score) => totalscore + parseInt(score.Xs,10),0)});
        
    }
  }
  
  render() {
    return (
      <Container component="main" maxWidth="xs">
          <Grid container spacing={1} align="center">      
            <Grid item xs={12}>
              <Typography variant="h5" color="primary">Mover</Typography>  
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" color="secondary">{this.state.competitor.name} ({this.state.competitor.nickname})</Typography>
              <Typography variant="body1" color="inherit">
                Division: {this.state.competitor.division}<br/> 
                Grade: {this.state.competitor.grade}</Typography>  
            </Grid>

            {this.state.scoreSubmitted ? 
              //if the score has been submitted render a "finished" message
              <Grid item xs={12}>
                <Typography variant="h5" color="inherit">
                  Score: {this.state.totalScore}/{this.state.totalXs}X has been successfully submitted.
                </Typography>
              </Grid>
            : 
              //otherwise render the scorecard so the score can be recorded
              <>
                <Grid item xs={12} >
                  <TableContainer component={Paper}>
                    <Table align='center' size='small' padding="none">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">X</TableCell>
                          <TableCell align="center">10</TableCell>
                          <TableCell align="center">8</TableCell>
                          <TableCell align="center">5</TableCell>
                          <TableCell align="center">0</TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">Penalty</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.scores.map((score) => (
                          <TableRow key={score.targetNumber}>
                            <TableCell align="center" padding="checkbox" size="medium">
                              <img src={TargetIcon} alt='' height='25px' width='25px'/>
                              {score.targetNumber}
                            </TableCell>
                            <TableCell className='cell' align="center">
                              <TextField key={score.targetNumber + '.Xs'} id={score.targetNumber + '.Xs'} variant="outlined" label=""           type="number" onKeyDown={this.handleKeyDown} onBlur={this.handleKeyDown} onFocus={event => {event.target.select()}} />
                            </TableCell>
                            <TableCell className='cell' padding="none" align="center">
                              <TextField key={score.targetNumber + '.tens'} id={score.targetNumber + '.tens'} variant="outlined" label=""     type="number" onKeyDown={this.handleKeyDown} onBlur={this.handleKeyDown} onFocus={event => {
                              event.target.select()}}/>
                            </TableCell>
                            <TableCell className='cell' padding="none" align="center">
                              <TextField key={score.targetNumber + '.eights'} id={score.targetNumber + '.eights'} variant="outlined" label="" type="number" onKeyDown={this.handleKeyDown} onBlur={this.handleKeyDown} onFocus={event => {
                              event.target.select()}}/>                
                            </TableCell>
                            <TableCell className='cell' padding="none" align="center">
                              <TextField key={score.targetNumber + '.fives'} id={score.targetNumber + '.fives'} variant="outlined" label=""   type="number" onKeyDown={this.handleKeyDown} onBlur={this.handleKeyDown} onFocus={event => {
                              event.target.select()}}/>
                            </TableCell>
                            <TableCell className='cell' padding="none" align="center">
                              <TextField key={score.targetNumber + '.zeros'} id={score.targetNumber + '.zeros'} variant="outlined" label=""   type="number" onKeyDown={this.handleKeyDown} onBlur={this.handleKeyDown} onFocus={event => {
                              event.target.select()}}/>
                            </TableCell>
                            <TableCell className='cell' padding="none" align="center">
                            </TableCell>
                            <TableCell className='cell' padding="none" align="center">
                              <TextField key={score.targetNumber + '.penalties'} id={score.targetNumber + '.penalties'} variant="filled" label="" type="number" align="center" onKeyDown={this.handleKeyDown} onBlur={this.handleKeyDown} onFocus= {event => {
                              event.target.select()}}/>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow >
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">x 10</TableCell>
                          <TableCell align="center">x 10</TableCell>
                          <TableCell align="center">x 8</TableCell>
                          <TableCell align="center">x 5</TableCell>
                          <TableCell align="center">x 0</TableCell>
                          <TableCell align="center"> - </TableCell>
                          <TableCell align="center">x 10</TableCell>
                        </TableRow>
                        <TableRow >
                          <TableCell align="center" size="medium">&Sigma;</TableCell>
                          <TableCell align="center">{this.state.points.Xs}</TableCell>
                          <TableCell align="center" size="medium">{this.state.points.tens}</TableCell>
                          <TableCell align="center" size="medium">{this.state.points.eights}</TableCell>
                          <TableCell align="center" size="medium">{this.state.points.fives}</TableCell>
                          <TableCell align="center" size="medium">{this.state.points.zeros}</TableCell>
                          <TableCell align="center" size="medium">-</TableCell>
                          <TableCell align="center" size="medium">{this.state.points.penalties}</TableCell>
                        </TableRow>        
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" color="inherit">
                    Score: {this.state.totalScore}/{this.state.totalXs}X
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button id="submitScore" variant="contained" color="primary" size="large"  onClick={this.submitScore} disabled={this.state.submitDisabled}>Submit Score</Button> 
                </Grid>
              </>
            }
          </Grid>
        </Container>  
    );
  }          
}
             

export default MoverScorecard;