import React from 'react';
import { Redirect, Route } from "react-router-dom";
import { config } from '../config/Constants';


const PrivateRoute = ({ component: Component, ...rest }) => {
  const storedData = sessionStorage.getItem(config.ACCESS_TOKEN_NAME);
  console.log("PrivateRoute referrer: ", rest.location.pathname);
  return ( 
    <Route {...rest} render={(props) => (
      storedData === null || storedData.user === ""
        ? <Redirect to={{
            pathname: "/login",
            state: {referrer: rest.location.pathname}
            }}
          />
        : <Component {...props} />
      )} 
    />
  )
}

export default PrivateRoute;