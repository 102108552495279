import React, { Component } from 'react';
import PracticalScorecard from './PracticalScorecard';
import Carousel from 'react-bootstrap/Carousel';

class Practical extends Component {
  
  constructor(props){
    super(props);
    this.state={
      index: 0,
    }
    console.log(props);
    console.log(this.state);
  }
  
  handleSelect = (selectedIndex, e) => {
    this.setState({index: selectedIndex});
  };
    
  render() {
    return (
      <Carousel activeIndex={this.state.index} onSelect=          
        {() => this.handleSelect()} indicators={false} controls={this.props.competitors.length === 1 ? (false):(true)} data-interval={false} interval={68000}>
        {this.props.competitors.map((item,index) => {
          return <Carousel.Item key={index}>
            <PracticalScorecard competition={this.props.competition} competitor={item}  division={item.username} grade={item.username} scorer={this.props.scorer}/>
          </Carousel.Item>
        })}     
      </Carousel>
    );
  }
}

export default Practical;