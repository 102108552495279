import './App.css';
import { config } from './config/Constants';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Home from './pages/Home/Home';
import logo from './assets/images/actionmatch-512.png';
import StartMatch from './pages/Match/StartMatch';
import NavBar from './components/NavBar';
import NotFound from './navigation/NotFound';
import SignIn from './components/SignIn';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import PrivateRoute from './components/PrivateRoute';
import Season from './pages/Season';
import Competition from './pages/Competition/Competition';
import React, { Component } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import UserProfile from './pages/Profile/UserProfile';


const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: 5,
        height: '2em',
      },
    },
    MuiFilledInput: {
      input: {
        padding: 5,
        height: '2em',
      },
    },
  }
});


export default class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      user: {
        id: "",
        name: "",
        userDetails: {
          firstname: "",
          lastname:  "",
          nickname:  "",
          email:     "",
          address:   "",
          password:  "",
        }, 
        club: {
          clubName: "",
          clubMemberId: "",
        },
        disciplines: [{division: "", grade:""}],
      },
      isLoggedIn: false,
      loginTokenExpirationTime: null,
      userTemplate: null,
    }
    this.login  = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.updateUserDetails = this.updateUserDetails.bind(this);
//    console.log("App.constructor PUBLIC_URL: ", `${process.env.PUBLIC_URL}`);
//    console.log("App.constructor state: ", this.state);
  }
  
  componentDidMount(){
    //revisit this, only useful on initial app load, otherwise state.user contains data
    this.setState({userTemplate: this.state.user});
    const storedData = JSON.parse(sessionStorage.getItem(config.ACCESS_TOKEN_NAME));
    
    //storedDate object is valid?
    //then check whether cache is valid (expiration time in the future)
    if(storedData && storedData.user &&  new Date(storedData.expirationTime) > new Date()){
      this.setState({isLoggedIn: true});
      this.setState({user: storedData.user});
      this.setState({setTokenExpirationTime: storedData.expirationTime});
    }
    console.log("App.componentDidMount: ", storedData);
  }
  
  login(user) {
    //console.log("App.login: ", user);
    this.setState({isLoggedIn: true});
    this.setState({user: user});
    const expiration = new Date(new Date().getTime() + 1000 * 60  * 60);
    //store user data in sesison storage
    sessionStorage.setItem(config.ACCESS_TOKEN_NAME,                      JSON.stringify({user,expirationTime: expiration.toISOString()}));
  }
  
  logout() {
    this.setState({isLoggedIn: false});
    this.setState({user: this.state.userTemplate});
    this.setState({setTokenExpirationTime: null});
    sessionStorage.clear();
  }
  
  updateUserDetails(details){
    let user = this.state.user;
    user.userDetails = details; 
    this.setState({user: user});
    //console.log("App.updateUserDetails: ", details);
  }
  
  render() {
    console.log("App.render: user: ", this.state.user);
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter >
            <img src={logo} className="App-logo-background" alt="logo" />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <NavBar user={this.state.user} logout={this.logout}/>  
              </Grid>
              <Grid item xs={12}>
                <Switch>
                  <Route exact path='/login'>
                    <SignIn login={this.login} /> 
                  </Route>          
                  <Route exact path='/register'>
                    <Register login={this.login} />
                  </Route>
      
                  <Route exact path='/resetpassword' component={ResetPassword} />  

                  <Route exact path='/'         component={Home} />
                  <Route exact path='/home'     component={Home} />  

                  <Route exact path='/season' component={Season} /> 

                  <Route exact path='/competition' component={Competition} /> 

                  <PrivateRoute exact path="/profile">
                    <UserProfile user={this.state.user} updateUserDetails={this.updateUserDetails}/>
                  </PrivateRoute>
                  <PrivateRoute exact path='/startmatch'>
                    <StartMatch user={this.state.user} />
                  </PrivateRoute>
                  <Route path='*' component={NotFound} />
                </Switch>
              </Grid>
            </Grid>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}
