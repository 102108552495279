import React, {Component} from 'react';
import { Redirect, Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LinkExternal from '@material-ui/core/Link';
import LockOpen from '@material-ui/icons/LockOpen';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PasswordInput from './PasswordInput';

const styles = theme => ({
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
});


class Register extends Component{
  constructor(props){
    super(props);
    this.state = {
        submitDisabled: true,
        redirectToProfile: false,
        email: "",
        password: "",
        nickname: "",
        firstname: "",
        lastname: "", 
    };
    this.registerUser        = this.registerUser.bind(this);
    this.handleNickname      = this.handleNickname.bind(this);
    this.handleFirstname     = this.handleFirstname.bind(this);
    this.handleLastname      = this.handleLastname.bind(this);
    this.handleEmail         = this.handleEmail.bind(this);
    this.handlePassword      = this.handlePassword.bind(this);
    this.checkRegisterButton = this.checkRegisterButton.bind(this);
    console.log("Register.costructor: props: " + this.props);
  }
  
  async registerUser() {
    const url = "/cgi-bin/register.cgi";
        
    const user = { 
      firstname: this.state.firstname,
      lastname:  this.state.lastname,
      nickname:  this.state.nickname,
      email:     this.state.email,
      password:  this.state.password
    };
    
    console.log("Register.registerUser: " + url, user);
    
    try {
      let res = await axios.post(url, user);
      console.log("Register.registerUser: result: ", res); 
      //set user context in App
      this.props.login(res.data);
      //trigger redirect to profile page
      this.setState({redirectToProfile: true});
    }
    catch (error) {
      console.log(error);
    }
  }
  
  handleNickname(e){
   console.log("Register.handleNickname: ", e.target.value); 
   this.setState({nickname: e.target.value});
   this.checkRegisterButton(e.target.value, this.state.firstname, this.state.lastname, this.state.email, this.state.password);
  }
  
  handleFirstname(e){
    console.log("Register.handleFirstname: ", e.target.value);
    this.setState({firstname: e.target.value});
    this.checkRegisterButton(this.state.nickname, e.target.value, this.state.lastname, this.state.email, this.state.password);
  }
  
  handleLastname(e){
    console.log("Register.handleLastname: ", e.target.value);
    this.setState({lastname: e.target.value});
    this.checkRegisterButton(this.state.nickname, this.state.firstname, e.target.value, this.state.email, this.state.password);
  }
  
  handleEmail(e){
    console.log("Register.handleEmail: ", e.target.value);
    this.setState({email: e.target.value});
    this.checkRegisterButton(this.state.nickname, this.state.firstname, this.state.lastname, e.target.value, this.state.password);
    
  }
  
  handlePassword(e){
    console.log("Register.handlePassword: ", e.target.value);
    this.setState({password: e.target.value});
    this.checkRegisterButton(this.state.nickname, this.state.firstname, this.state.lastname, this.state.email, e.target.value);
  }
  
  checkRegisterButton(nickname, firstname, lastname, email, password){
    if (firstname === "" || 
        lastname === ""  ||
        email === ""      ||
        password === ""){
      this.setState({submitDisabled: true});
    } else {
      this.setState({submitDisabled: false});
    }
  }
  
  
  render () {
    const { classes } = this.props;
    console.log("Register.render: redirectToProfile: ", this.state.redirectToProfile);
    
    //if registration is successful, redirect to profile page
    if(this.state.redirectToProfile){
      return <Redirect to={"/profile"} />
    }
    
    return (
      <Container component="main" maxWidth="xs">
        <Grid container spacing={1} align="center">      
          <Grid item xs={12}>
            <Avatar className={classes.avatar}>
              <LockOpen />
            </Avatar>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h4" variant="h4">
              Register
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="firstname"
              value={this.state.firstname}
              onChange={this.handleFirstname}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lastname"
              value={this.state.lastname}
              onChange={this.handleLastname}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="nickame"
              label="Nickname"
              name="nickname"
              autoComplete="nickname"
              autoFocus
              value={this.state.nickname}
              onChange={this.handleNickname}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={this.state.email}
              onChange={this.handleEmail}
            />
            <PasswordInput
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              value={this.state.password}
              onChange={this.handlePassword}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"

              disabled={this.state.submitDisabled}
              onClick={this.registerUser}>
              Register
            </Button>
          </Grid>
          <Grid container align="center">
            <Grid item xs={12}>
              <Link href="mailto:derekbyrnes@derekbyrnes.com?Subject=Action Match account issues&body=I am having issues logging in, please help! my name is... " variant="body2">
                Having issues?
              </Link>
            </Grid>
            <Grid item xs={12}><p/></Grid>      
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
                <LinkExternal color="inherit" href="http://derekbyrnes.com">
                  derekbyrnes.com
                </LinkExternal>{' '}
                {new Date().getFullYear()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default withStyles(styles)(Register);