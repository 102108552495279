import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';

class NotFound extends Component {
  render() {
    return (
      <Container maxWidth="xs" >
        <Grid container spacing={1} align="center">      
          <Grid item xs={12}>
            <Typography component="body1" variant="body1">
              "{this.props.location.pathname}" not found.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(NotFound);
  