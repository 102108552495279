import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import LinkExternal from '@material-ui/core/Link';
import Lock from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
//import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PasswordInput from './PasswordInput';

const styles = theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});


class SignIn extends Component {

  constructor(props){
    super(props);
    this.state = {
      submitDisabled: true,
      email: "",
      password: "",
      open: true,
      rememberMe: false,
      isLoggingIn: false,
    };
    this.logUserIn = this.logUserIn.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
    this.handleRememberMe = this.handleRememberMe.bind(this);
    //console.log("SignIn.constructor: props: ", this.props);
    //console.log("SignIn.componentDidMount PUBLIC_URL: ", `${process.env.PUBLIC_URL}`);
  }
  
  async logUserIn() {
    this.setState({isLoggingIn: true});
    const url = "/cgi-bin/login.cgi";
    console.log(url);
    try {
      let res = await axios.post(url, {"email":this.state.email, "password": this.state.password});
      console.log("SignIn.logUserIn result: ", res); 
      //set user context in App
      this.props.login(res.data);
      
      if(this.props.location.state) {
        this.props.history.push(this.props.location.state.referrer);
      }
      else {
        this.props.history.push('/home');
      }
    }
    catch (error) {
      console.log(error);
    }
    this.setState({isLoggingIn: false});
  }

  handleEmailInput(e){
    this.setState({email: e.target.value});         
    //console.log(e.target.value);
    if (e.target.value !== "" && this.state.password !== "") {
      this.setState({submitDisabled: false});
    } else {
      this.setState({submitDisabled: true});
    }
    if(this.state.rememberMe){
      //console.log("store state in browser for later");
    }
  }
  
  handlePasswordInput(e){
    this.setState({password: e.target.value});         
    //console.log(e.target.value);
    if (this.state.email !== "" && e.target.value !== "") {
         this.setState({submitDisabled: false});
     } else {
         this.setState({submitDisabled: true});
     }
     if(this.state.rememberMe){
       //console.log("store state in browser for later");
     }
  }
  
  handleRememberMe(e){
    console.log("SignIn.handleRememberMe: ", e.target.value);
    this.setState({rememberMe: !this.state.rememberMe});
  }
  
  handleClose(e){
    this.setState({open: false});
  };
  
  render () {
    const { classes } = this.props;            

    return (
      <Container maxWidth="xs" >
        <Grid container spacing={1} align="center">      
          <Grid item xs={12}>
            <Avatar className={classes.avatar}>
              <Lock />
            </Avatar>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h4" variant="h4">
              {"Sign in"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={this.handleEmailInput}
              autoFocus/>
            <PasswordInput
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              id="password"
              onChange={this.handlePasswordInput}/>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" onChange={this.handleRememberMe}/>}
              label="Remember me"/>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={this.state.submitDisabled}
              onClick={this.logUserIn}>
              Sign In
            </Button>
          </Grid>
          <Grid container spacing={0} align="center" direction="column">            
            <Grid item xs={12}>
              <Link to="/resetPassword" href="/resetPassword" variant="body2">
                {"Forgot password?"}
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link to="/register" href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12}><p/></Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              <LinkExternal color="inherit" href="http://derekbyrnes.com">
                derekbyrnes.com
              </LinkExternal>{' '}
              {new Date().getFullYear()}
            </Typography>
          </Grid>
        </Grid>
        {this.props.location.state && this.props.location.state.referrer &&
          <Snackbar open={this.state.open} autoHideDuration={5000} onClose={this.handleClose}>
            <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity="info">
              Sign-in to access {this.props.location.state.referrer}
            </MuiAlert>
          </Snackbar>
        }
        <Backdrop className={classes.backdrop} open={this.state.isLoggingIn}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(SignIn));
