import React, {Component} from 'react';
import PasswordInput from '../../components/PasswordInput';
import Accordion from '@material-ui/core/Accordion';
import AddIcon from '@material-ui/icons/Add';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
//import axios from 'axios';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
//import Card from '@material-ui/core/Card';
//import CardContent from '@material-ui/core/CardContent';
//import CardHeader from '@material-ui/core/CardHeader';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '66.66%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '33.33%',
  },
  plus: {
    width: 0,
    height: 10,
  }, 
  bin: {
    width: 10,
    height: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});


class UserProfile extends Component {
    
  constructor(props){
    super(props);
    this.state = {
      userBackup: JSON.parse(JSON.stringify(props.user)),

      user : props.user,
      currentPassword:   "",
      newPassword:       "",
      newPasswordRepeat: "", 
      
      allDivisions: ["Open", "Metallic Sight", "Production"],
      allGrades: ["High Master", "Master", "Expert", "Sharpshooter", "Marksman", "ungraded"],
      comboOpen: false,
      openDivisions: false,
      openGrades: false,
      isDisciplinesLoading: false,
      isGradesLoading: false,
      addMoreDivisions: true,
      
      isPasswordChanged: false,
      isMandatoryUserDetailsCompleted: false,
      isMandatoryClubDetailsCompleted: false,
      isMandatoryDisciplineDetailsCompleted: false,
      isDetailsChanged: false,
      
      isSaving: false,
      isSaveError: false, 
      isChangePasswordExpanded: false,
    };
    this.handlePasswordChange    = this.handlePasswordChange.bind(this);
    this.handleChangeUserDetails = this.handleChangeUserDetails.bind(this);
    this.handleChangeClubDetails = this.handleChangeClubDetails.bind(this);
    this.checkMandatory          = this.checkMandatory.bind(this);
    this.checkChanged            = this.checkChanged.bind(this);
    this.saveDetails             = this.saveDetails.bind(this);
    this.savePassword            = this.savePassword.bind(this);
    this.closeAlert              = this.closeAlert.bind(this);
    this.expandPassword          = this.expandPassword.bind(this);
              
    this.addDiscipline   = this.addDiscipline.bind(this);
    console.log("UserProfile.constructor: props: ", this.props);
    
    this.getSelected = this.getSelected.bind(this);
  }
  
  componentDidMount(){
    this.checkMandatory(this.state.user.userDetails, this.state.user.club, this.state.user.disciplines);
    this.checkChanged(this.state.user.userDetails, this.state.user.club, this.state.user.disciplines);
  }
  
  handlePasswordChange(e){
    console.log("UserProfile.handlePasswordChange: " , e.target.id);
//    switch(e.target.id){
//      case "password":  
//        this.setState({currentPassword: e.target.value});
//        break;
//      case "newPassword":
//        this.setState({newPassword: e.target.value});
//        break;
//      case "newPasswordRepeat":
//        this.setState({newPasswordRepeat: e.target.value});
//        break;
//      default:
//        console.log("UserProfile.handlePasswordChange: " , e.target.id);    
//        return;
//    }
    const { name, value } = e.target;
    console.log("UserProfile.handlePasswordChange: " , name, ": ", value);
    this.setState({ [name]: value });
    if( this.state.currentPassword !== "" &&
        this.state.newPassword !== "" && 
        this.state.newPasswordRepeat !== "" &&
        this.state.newPassword === this.state.newPasswordRepeat)
    {
      this.setState({isPasswordChanged: true});
    } 
  }
  
  expandPassword(){
    this.setState({isChangePasswordExpanded: !this.state.isChangePasswordExpanded})
  }

  handleChangeUserDetails(e){
    console.log("UserProfile.handleChangeUserDetails: " , e.target.id);
    let user = this.state.user;
    switch(e.target.id){
      case "firstname":  
        user.userDetails.firstname = e.target.value;
        break;
      case "lastname":
        user.userDetails.lastname = e.target.value;
        break;
      case "nickname":
        user.userDetails.nickname = e.target.value;
        break;
      case "address":
        user.userDetails.address = e.target.value;
        break;
      case "email":
        user.userDetails.email = e.target.value;
        break;
      case "password":
        user.userDetails.password = e.target.value;
        break;
      default:
        console.log("UserProfile.handleChangeUserDetails: no matching id for: " , e.target.id);    
        return;
    }
    this.setState({user: user});
    this.checkMandatory(user.details,this.state.user.club, this.state.user.disciplines);
    this.checkChanged(user.details,this.state.user.club, this.state.user.disciplines);
  }
  
  handleChangeClubDetails(e){
    console.log("UserProfile.handleChangeClubDetails: " , e.target.id);
    let user = this.state.user;
    switch(e.target.id){
      case "clubname":  
        user.club.clubName = e.target.value;
        break;
      case "clubmemberid":
        user.club.clubMemberId = e.target.value;
        break;
      default:
        console.log("UserProfile.handleChangeClubDetails: no matching id for: " , e.target.id);    
        return;
    }
    this.setState({user: user});
    this.checkMandatory(this.state.user.userDetails, user.club, this.state.user.disciplines);
    this.checkChanged(this.state.user.userDetails, user.club, this.state.user.disciplines);
  }
  
  addDiscipline(){
    console.log("UserProfile.addDiscipline: add");
    let user = this.state.user;
    user.disciplines = user.disciplines.concat({division: null, grade: null});
    this.setState({user: user});
     if(user.disciplines.length >= this.state.allDivisions.length){
      this.setState({addMoreDivisions: false});
    }
    this.checkMandatory(this.state.user.userDetails, this.state.user.club, user.disciplines);
    this.checkChanged(this.state.user.userDetails, this.state.user.club, user.disciplines);
  }
  
  removeDisciplineSelection(i){
    console.log("UserProfile.updateDisciplines: remove selector");
    let user = this.state.user;
    user.disciplines.splice(i,1) ; 
    this.setState({user: user});
    if(user.disciplines.length < this.state.allDivisions.length){
      this.setState({addMoreDivisions: true});
    } 
    this.checkMandatory(this.state.user.userDetails, this.state.user.club, user.disciplines);
    this.checkChanged(this.state.user.userDetails, this.state.user.club, user.disciplines);
  }
  
  updateDisciplines(index, e, value, field){
    //console.log("UserProfile.updateDisciplines: update", index, e, value);
    let user = this.state.user;
    if(value===null){
      if(field==="division"){
        user.disciplines[index].division = null;
      } else {
        user.disciplines[index].division = null;
      }
    }
    else if(field==="division"){
      user.disciplines[index].division = value;
    }
    else if(field==="grade"){
      user.disciplines[index].grade = value;
    }
    this.setState({user: user});
    this.checkMandatory(this.state.user.userDetails, this.state.user.club, user.disciplines);
    this.checkChanged(this.state.user.userDetails, this.state.user.club, user.disciplines);
  }
 
  checkMandatory(details,club,disciplines){
    if(!details || details.firstname === "" || details.lastname === "" || details.email === ""){  
      this.setState({isMandatoryUserDetailsCompleted: false});
    } else {
      this.setState({isMandatoryUserDetailsCompleted: true});
    }
    
    if(!club || club.clubName === "" || club.clubMemberId === ""){
      this.setState({isMandatoryClubDetailsCompleted: false});
    } else {
      this.setState({isMandatoryClubDetailsCompleted: true});
    }
    
    if(disciplines && (disciplines.length > 0)){
      let i=0;
      while(i<disciplines.length){
        if(disciplines[i].division === null || disciplines[i].grade === null) {
          //found at least one incomplete discipline, so return
          this.setState({isMandatoryDisciplineDetailsCompleted: false});
          return;
        }
        i++;
      }
      //didnt find and blank disciplines, so must be completed
      this.setState({isMandatoryDisciplineDetailsCompleted: true});
    } else {
      this.setState({isMandatoryDisciplineDetailsCompleted: false});
    }
  }
  
  checkChanged(details,club,disciplines){
    if(details && 
       details.firstname  === this.state.userBackup.userDetails.firstname && 
       details.lastname   === this.state.userBackup.userDetails.lastname  && 
       details.nickname   === this.state.userBackup.userDetails.nickname && 
       details.email      === this.state.userBackup.userDetails.email &&
       details.address    === this.state.userBackup.userDetails.address) {
      
      if(club &&
         club.clubName     === this.state.userBackup.club.clubName &&
         club.clubMemberId === this.state.userBackup.club.clubMemberId){
       
        if(disciplines &&
          disciplines.length === this.state.userBackup.disciplines.length &&
          disciplines.reduce((p, c, i)=>{
            //console.log(p,c,i);
            let result = p && 
                        c.division === this.state.userBackup.disciplines[i].division && 
                        c.grade === this.state.userBackup.disciplines[i].grade;
            return result;
            },0)===0){
     
          this.setState({isDetailsChanged: false});
        } else {
          this.setState({isDetailsChanged: true});
        }
      } else {
        this.setState({isDetailsChanged: true});
      }
    } else {
      this.setState({isDetailsChanged: true});
    }   
  }
  
  async saveDetails(){
    this.setState({isSaving: true});
    console.log("UserProfile.saveDetails: ");
    
    const url = "/cgi-bin/updateUserDetails.cgi";
    console.log(url, this.state.user);
    try {
      let res = await axios.post(url, {"user": this.state.user});
      console.log("UserProfile.saveDetails: result: ", res); 
      
      //updates saved
      this.setState({isDetailsChanged: false});
    }
    catch (error) {
      console.log(error);
      this.setState({isSaveError: true});
    }
    this.setState({isSaving: false});
  }
  
  closeAlert(e){
    this.setState({isSaveError: false});
  };
  
  async savePassword(){
    this.setState({isSaving: true});
    console.log("UserProfile.savePassword: ");
    
    const url = "/cgi-bin/updatePassword.cgi";
    const postData = {"currentPassword": this.state.currentPassword, 
                      "newPassword": this.state.newPassword,
                      "memberid": this.state.user.id};
    console.log(url, this.state.newPassword);
    try {
      let res = await axios.post(url, postData);
      console.log("UserProfile.savePassword: result: ", res); 
      
      //updates saved
      this.setState({isPasswordChanged: false});
      this.setState({currentPassword: ""});
      this.setState({newPassword: ""});
      this.setState({newPasswordRepeat: ""});
      this.setState({isChangePasswordExpanded: false});
    }
    catch (error) {
      console.log(error);
      this.setState({isSaveError: true});
    }
    this.setState({isSaving: false});
  }
  
  
  
  
  renderHeader(classes){
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary" align="center">Your Profile</Typography>  
        </Grid>
        <Grid item xs={12}>
          {this.state.isMandatoryUserDetailsCompleted && 
           this.state.isMandatoryClubDetailsCompleted &&
           this.state.isMandatoryDisciplineDetailsCompleted ? 
            "" 
          : 
            <Typography variant="body1" color="error">* Profile updates are required before your profile can be saved</Typography>
          }
          {this.state.isDetailsChanged?
            <Typography variant="body1" color="error">please save profile changes</Typography>
            : ""}
        </Grid>
      </>
    )
  }
  
  renderPassword(classes){
    return (
      <>
        <Grid item xs={12} align="left">
          <Accordion expanded={this.state.isChangePasswordExpanded} onChange={this.expandPassword}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="password"
              id="password">
              <Typography className={classes.heading} color="primary">Password</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12} align="center">
                <PasswordInput
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  id="currentPassword"
                  label="Old Password"
                  name="currentPassword"
                  autoComplete="currentPassword"

                  helperText={(this.state.currentPassword === ""? " Password rquired" : "")}
                  onChange={this.handlePasswordChange}
                  value={this.state.currentPassword}
                />
                <PasswordInput
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="newPassword"
                  label="New Password"
                  name="newPassword"
                  autoComplete="newPassword"
                  disabled={this.state.currentPassword === ""}

                  error={this.state.currentPassword !== "" && 
                         this.state.newPassword === ""}
                  helperText={(this.state.newPassword === ""? "New password required" :   
                              (this.state.newPassword === this.state.newPasswordRepeat? "": "password does not match")
                              )
                             }
                  onChange={this.handlePasswordChange}
                  value={this.state.newPassword}
                />
                <PasswordInput
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="newPasswordRepeat"
                  label="Repeat New Password"
                  name="newPasswordRepeat"
                  autoComplete="newPasswordRepeat"
                  disabled={this.state.currentPassword === ""}

                  error={this.state.currentPassword !== "" && 
                         (this.state.newPassword     === "" || 
                         this.state.newPassword     !== this.state.newPasswordRepeat)}
                  helperText={(this.state.newPassword === "" ? "Re-enter new password" :     
                              (this.state.newPassword !== this.state.newPasswordRepeat? "password does not match" : "")
                              )
                             }
                  onChange={this.handlePasswordChange}
                  value={this.state.newPasswordRepeat}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} align="center">
          <Button variant="contained"
            onClick={this.savePassword} 
            color="secondary"
            startIcon={<SaveIcon />}
            align="center"
            disabled={this.state.currentPassword === "" || 
                      this.state.newPassword     === "" || 
                      this.state.newPassword     !== this.state.newPasswordRepeat} 
            >
            Save Password
          </Button> 
        </Grid>
      </>
    )
  }
  
  renderUserDetails(classes){
    return(
      <Grid item xs={12} align="left">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header">
            <Typography className={classes.heading} color={this.state.isMandatoryUserDetailsCompleted? "primary" : "error"}>My Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12} align="center">
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstname"
                label="First Name"
                name="firstName"
                autoComplete="firstname"
                error={this.state.user.userDetails.firstname === ""}
                helperText={(this.state.user.userDetails.firstname === ""? "Name rquired" : "")}
                onChange={this.handleChangeUserDetails}
                value={this.state.user.userDetails.firstname}                     
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus
                id="lastname"
                label="Last Name"
                name="lastName"
                autoComplete="lastname"
                error={this.state.user.userDetails.lastname === ""}
                helperText={(this.state.user.userDetails.lastname === ""? "Name rquired" : "")}
                onChange={this.handleChangeUserDetails}
                value={this.state.user.userDetails.lastname}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="nickname"
                label="Nickname"
                name="nickname"
                autoComplete="nickname"
                onChange={this.handleChangeUserDetails}
                value={this.state.user.userDetails.nickname}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="address"
                label="Address"
                name="address"
                autoComplete="address"
                onChange={this.handleChangeUserDetails}
                value={this.state.user.userDetails.address}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                error={this.state.user.userDetails.email === ""}
                helperText={(this.state.user.userDetails.email === ""? "Email rquired" : "")}
                onChange={this.handleChangeUserDetails}
                value={this.state.user.userDetails.email}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }
  
  renderHomeClub(classes){
    return (
      <Grid item xs={12} align="left">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header">
            <Typography className={classes.heading} color={this.state.isMandatoryClubDetailsCompleted? "primary" : "error"}>Home Club</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12} align="center">
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="clubname"
                label="Club Name"
                name="clubname"
                autoComplete="clubName"
                value={this.state.user.club.clubName}
                error={this.state.user.club.clubName === ""}
                helperText={(this.state.user.club.clubName === ""? "Club rquired" : "")}
                onChange={this.handleChangeClubDetails}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="clubmemberid"
                label="Club Member Id"
                name="clubmemberid"
                autoComplete="clubmemberid"
                value={this.state.user.club.clubMemberId}
                error={this.state.user.club.clubMemberId === ""}
                helperText={(this.state.user.club.clubMemberId === ""? "Club rquired" : "")}
                onChange={this.handleChangeClubDetails}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }
  
  getSelected(option, value){
    let isTrue = option === value;
    return isTrue;
  }
  
  renderDisciplines(classes){
    return(
      <Grid item xs={12} align="left">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header">
            <Typography className={classes.heading} color={this.state.isMandatoryDisciplineDetailsCompleted? "primary" : "error"}>Disciplines</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12} align="center">
              <TableContainer >
                <Table>
                  <TableBody>
                  {this.state.user.disciplines.map((d, i) => (
                    <TableRow key={i} padding="none" >
                      <TableCell width="45%" padding="none" >
                        <Autocomplete
                          id={`division`+i}
                          value={d.division}
                          onOpen=  {() => {this.setState({openDisciplines: true});}}
                          onClose= {() => {this.setState({openDisciplines: false});}}
                          onChange={(event, value) => {this.updateDisciplines(i, event, value, "division")}}
                          getOptionSelected={(option, value) => this.getSelected(option, value) }
                          getOptionLabel={(option) => option}
                          options={this.state.allDivisions}
                          loading={this.state.isDisciplinesLoading}
                          renderInput={(params) => (
                            <TextField 
                              {...params}
                              label="Division"
                              margin="none"
                              id={`division`+i}
                              multiline="true"
                              error={d.division === null}
                              helperText={(d.division === null ? "Division rquired" : "")}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {this.state.isDisciplinesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </TableCell> 
                      <TableCell width="45%" padding="none" >
                        <Autocomplete
                          id={`grade`+i}
                          value={d.grade}
                          open={this.state.openGrades}
                          onOpen=  {() => {this.setState({openGrades: true});}}
                          onClose= {() => {this.setState({openGrades: false});}}
                          onChange={(event, value) => {this.updateDisciplines(i, event, value, "grade")}}
                          getOptionSelected={(option, value) => option === value }
                          getOptionLabel={(option) => option}
                          options={this.state.allGrades}
                          loading={this.state.isGradesLoading}
                          renderInput={(params) => (
                            <TextField 
                              {...params}
                              label="Grade"
                              margin="none"
                              multiline="true"
                              id={`grade`+i}
                              error={d.grade === null}
                              helperText={(d.grade === null ? "Grade rquired" : "")}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {this.state.isGradesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </TableCell> 
                      <TableCell width="10%" padding="none" >
                         <Button className={classes.bin} onClick={() => {this.removeDisciplineSelection(i)}}><DeleteIcon /></Button>
                      </TableCell> 
                    </TableRow>
                  ))}
               </TableBody>
            </Table>
          </TableContainer>
            {this.state.addMoreDivisions? 
              <Button align="center" onClick={this.addDiscipline}><AddIcon />Add Discipline</Button> 
            :
              ""
            }
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }
  
  renderSave(classes){
    return (
      <Grid item xs={12} align="center">
        <Button variant="contained" color="secondary" startIcon={<SaveIcon />} align="center" onClick={this.saveDetails} disabled={!(this.state.isDetailsChanged && this.state.isMandatoryUserDetailsCompleted && this.state.isMandatoryClubDetailsCompleted && this.state.isMandatoryDisciplineDetailsCompleted)}>Save Personal Details</Button> 
      </Grid>
    )
  }
  
  renderStatus(classes){
    return (
      <>
        <Snackbar open={this.state.isSaveError} autoHideDuration={5000} onClose={this.closeAlert}>
          <MuiAlert elevation={6} variant="filled" onClose={this.closeAlert} severity="error">
            Error Saving User Profile Updates
          </MuiAlert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={this.state.isSaving}>
          <CircularProgress color="primary" />
        </Backdrop>
      </>
    )
  }

  render(){
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="md">
        <Grid container spacing={1} align="center">      
          {this.renderHeader(classes)}
          {this.renderPassword(classes)}
          {this.renderUserDetails(classes)}
          {this.renderHomeClub(classes)}
          {this.renderDisciplines(classes)}
          {this.renderSave(classes)}
        </Grid>
        {this.renderStatus(classes)}
      </Container>
    )
  }
}

export default withStyles(styles)(UserProfile);
