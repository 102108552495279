import React, { Component } from 'react';
import { InputAdornment, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';


const styles = theme => ({
  eye: {
    cursor: 'pointer',
  },
});

class PasswordInput extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      passwordIsMasked: true,
    };
    this.togglePasswordMask = this.togglePasswordMask.bind(this);
  }

  togglePasswordMask(){
    this.setState({passwordIsMasked: !this.state.passwordIsMasked});
    //console.log("PasswordInput.passwordIsMasked: ", this.state.passwordIsMasked);
  }
  
  render() {
    const { classes } = this.props;
    
    return (
      <TextField
        type={(this.state.passwordIsMasked ? "password" : "text")}
        {...this.props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {this.state.passwordIsMasked ? 
                <Visibility className={classes.eye} onClick={this.togglePasswordMask}/> 
              : 
                <VisibilityOff className={classes.eye} onClick={this.togglePasswordMask}/>
              }
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

PasswordInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired,
};

export default withStyles(styles)(PasswordInput);