import Add from '@material-ui/icons/Add';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Delete from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Practical from './components/Practical';
import Barricade from './components/Barricade';
import Mover from './components/Mover';
import Plates from './components/Plates';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  accordionSummary: {
    padding: "0px 16px 0px 0px",
    margin: 0,
  },
  autocomplete: {
    flexGrow: 1,
    margin: "-12px 0px -12px 0px",
  },
  
  plus: {
    width: 10,
    height: 10,
  }, 
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
})


class StartMatch extends Component {
  
  constructor(props){
    super(props);
    this.state = {
        date: Date(),
        allCompetitions: [],
        allMatches:["Practical","Barricade","Mover","Plates"],
        allCompetitors: [],
        
        competition: {title: ""}, 
        match: "",
        competitors: [{name: ""}],
        
        startButtonDisabled: true,
        start: false,

        competitionsLoading: false,
        matchesLoading: false,
        competitorsLoading: false,
      
        addMoreCompetitors: true,
      
        newCompetitionTitle: "",
        newCompetitionDate: new Date().toISOString().substring(0, 10),    
        newCompetitionDays: 1,
        isNewCompetitionExpanded: false,
        isSelectCompetitionExpanded: false,
        isNewCompetitionDetailsNeeded: true,
      
        isShowStatus: false,
        statusType: "error", //one of error, warning, info, success
        statusMessage: "",
        isNewCompetitionSaving: false,
    }
    this.expandNewCompetition  = this.expandNewCompetition.bind(this);
    this.newCompetitionTitle   = this.newCompetitionTitle.bind(this);
    this.newCompetitionDate    = this.newCompetitionDate.bind(this);
    this.newCompetitionDays    = this.newCompetitionDays.bind(this);
    this.canSaveNewCompetition = this.canSaveNewCompetition.bind(this);
    this.saveNewCompetition    = this.saveNewCompetition.bind(this);
    this.startMatch            = this.startMatch.bind(this);
    this.closeAlert            = this.closeAlert.bind(this);
    console.log("StartMatch: constructor: ", props);
  }

  componentDidMount(){
    this.getCompetitions();
    this.getCompetitors();
  }
  
  startMatch(e){
    this.setState({startDisabled: true});
    //this.props.setCompetitionDetails(this.state.competitors,this.state.match, this.state.competition);
    console.log("start match: ", this.state.match);
    this.setState({start: true});
  }

  async getCompetitions(){
    this.setState({competitionsLoading: true});
    const uri = `/cgi-bin/getCompetitions.cgi`;
    //console.log("StartMatch.getCompetitions: uri: ", uri);
    try {
      let res = await axios.get(uri)
      this.setState({allCompetitions: res.data});
      //console.log(res);
    }
    catch (error) {
      //console.log(error);
    }      
    this.setState({competitionsLoading: false});
  }

  async getCompetitors(){
    this.setState({competitorsLoading: true});
    const uri = `/cgi-bin/competitor.cgi`;
    //console.log("StartMatch.getCompetitors: uri: ", uri);
    try {
      let res = await axios.get(uri);
      this.setState({allCompetitors: res.data});
    } 
    catch(error) {
          console.log(error);
    }      
    this.setState({competitorsLoading: false});
  }
  
  expandNewCompetition(event){
    if(event.currentTarget.id){
      this.setState({isNewCompetitionExpanded: !this.state.isNewCompetitionExpanded});
    }
  }
  newCompetitionTitle(event){
    this.setState({newCompetitionTitle: event.target.value})
    this.canSaveNewCompetition(event.target.value, this.state.newCompetitionDate, this.state.newCompetitionDays);
  }
  newCompetitionDate(event){
    this.setState({newCompetitionDate: event.target.value})
    this.canSaveNewCompetition(this.state.newCompetitionTitle, event.target.value, this.state.newCompetitionDays);
  }
  newCompetitionDays(event){
    this.setState({newCompetitionDays: event.target.value})
    this.canSaveNewCompetition(this.state.newCompetitionTitle, this.state.newCompetitionDate, event.target.value);
  }
  canSaveNewCompetition(title, date, days){
    if(title !== "" && days > 0) {
      this.setState({isNewCompetitionDetailsNeeded: false});
    } else {
      this.setState({isNewCompetitionDetailsNeeded: true});
    }
  }
  
  async saveNewCompetition() {
    this.setState({isNewCompetitionSaving: true});
    const uri = `/cgi-bin/newCompetition.cgi`;
    const postData = {"id": this.props.user.id,
                      "title": this.state.newCompetitionTitle, 
                      "date": this.state.newCompetitionDate,
                      "days": this.state.newCompetitionDays};
    //console.log("StartMatch.saveNewCompetition: uri: ", uri);
    try {
      let res = await axios.post(uri, postData);
      if(res.status === 200) {
        this.setState({isNewCompetitionDetailsNeeded: true});//disable save button
        this.setState({isNewCompetitionExpanded: false}); //close new comp twistie
        this.getCompetitions(); //reload competitions after update
        this.setState({statusMessage: "new competition created"});
        this.setState({statusType: "success"});
        this.setState({isShowStatus: true});
      } else {
        this.setState({statusMessage: res.statusText});
        this.setState({statusType: "error"});
        this.setState({isShowStatus: true});
      }
    } 
    catch(error) {
      console.log(error);
      this.setState({statusMessage: error.response.status + ": " + error.response.statusText});
      this.setState({statusType: "error"});
      this.setState({isShowStatus: true});
    }      
    this.setState({isNewCompetitionSaving: false});
  }
  
  updateCompetition(competition){
      let comp = this.state.competition;
      comp = competition===null?({title: ""}):(competition);
      this.setState({competition: comp});
      this.checkStartMatchButton(this.state.competitors, this.state.match, comp);
      //console.log(comp);
  }

 updateMatch(match){
    let m = match===null?(""):(match);
    this.setState({match: m});
    this.checkStartMatchButton(this.state.competitors, m, this.state.competition);
    //console.log(m);
 }

  updateCompetitors(i, person){
    let c = this.state.competitors;
    c[i] = person===null?({name:""}):(person);
    this.setState({competitors: c});
    this.checkStartMatchButton(c, this.state.match, this.state.competition);
    //console.log(c);
  }
  
  removeCompetitorSelection(index){
    let c = this.state.competitors;
    c.splice(index,1) ; 
    this.setState({competitors: c});
    this.checkStartMatchButton(c, this.state.match, this.state.competition);
    //console.log(c);
    if(c.length < 4){
      this.setState({addMoreCompetitors: true});
    } 
  }

  addCompetitorSelector(){
    let c = this.state.competitors.concat({name: ""});
    this.setState({competitors: c});
    this.checkStartMatchButton(c, this.state.match, this.state.competition);
    //console.log(c);
    if(c.length >= 4){
      this.setState({addMoreCompetitors: false});
    }
  }
  
  checkStartMatchButton = (competitors, match, competition) => {
    //if "" is in any name field in competitors array, we cannot start the match (invalid data)
    const isDisabled = ((competitors.length === 0 || match === "" || competitors.some(v => (v.name === ""))) ) || (competition.title === "");
    this.setState({startButtonDisabled: isDisabled});
  }
  
  closeAlert(){
    this.setState({isShowStatus: false});
  }

  header(classes){
    return (
      <Grid item xs={12}>
        <Typography variant="h5" color="primary" align="center">Start a Match</Typography>  
      </Grid>
    )
  }

  selectCompetition(classes){
    
    
    return (
      <Grid item xs={12}>
        <Accordion id="accordian" expanded={this.state.isNewCompetitionExpanded && !this.state.isSelectCompetitionExpanded} onChange={this.expandNewCompetition}>
          <AccordionSummary
            className={classes.accordionSummary}                 
            expandIcon={<Add />}
            id="select-competition-summary">
                  
            <Autocomplete
              className={classes.autocomplete}
              id="competition"
              open={this.state.isSelectCompetitionExpanded}
              onOpen={() => { this.setState({isSelectCompetitionExpanded: true})}}
              onClose={() => { this.setState({isSelectCompetitionExpanded: false})}}
              onChange={(event, value) => {this.updateCompetition(value)}}
              getOptionSelected={(option, value) => option.title === value.title }
              getOptionLabel={(option) => (new Date()).toLocaleDateString('en-AU','dd/MM/YYYY')  + ": " + option.title + ": day " + option.day}
              options={this.state.allCompetitions}
              loading={this.state.competitionsLoading}
              renderInput={(params) => (
                <TextField 
                  {...params}
                  label="Select Competition"
                  size="small"
                  variant="filled"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {this.state.competitionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </AccordionSummary >
          <AccordionDetails className={classes.accordionDetails}>
            <Grid container spacing={3}>      
              <Grid item xs={12} align="center">
                <TextField
                  id="competition-title"
                  label="New Competition Title"
                  type="text"
                  fullWidth
                  value={this.state.newCompetitionTitle}
                  onChange={this.newCompetitionTitle}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <TextField
                  id="competition-days"
                  label="Number of Competition Days"
                  type="text"
                  fullWidth
                  value={this.state.newCompetitionDays}
                  onChange={this.newCompetitionDays}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <TextField
                  id="competition-date"
                  label="New Competition Date"
                  type="date"
                  fullWidth
                  value={this.state.newCompetitionDate}
                  onChange={this.newCompetitionDate}
                  InputLabelProps={{ shrink: true, }}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <Button onClick={this.saveNewCompetition} disabled={this.state.isNewCompetitionDetailsNeeded}><SaveIcon/>Save New Competition</Button>
              </Grid>
            </Grid>              
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }
  
  selectMatch(){
    return (
      <Grid item xs={12}>
        <Autocomplete
          id="match"
          onChange={(event, value) => {this.updateMatch(value)}}
          getOptionSelected={(option, value) => option === value }
          getOptionLabel={(option) => option}
          options={this.state.allMatches}
          loading={this.state.matchesLoading}
          renderInput={(params) => (
            <TextField 
              {...params}
              label="Select Match"
              size="small"
              variant="filled"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {this.state.matchesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>     
    )
  }
  
  selectCompetitors(classes) {
    return (
      <>
        <Grid item xs={12} >
            <TableContainer component={Paper}>
              <Table align='center' size='small' padding="none">
                <TableBody>
                  {this.state.competitors.map((c, i) => (
                    <TableRow key={i}>
                      <TableCell align="center" padding="none" size="medium">
                        <Autocomplete
                          id={`compeitorlist_`+i}
                          onChange={(event, value) => {this.updateCompetitors(i, value)}}
                          getOptionSelected={(option, value) => option.name === value.name }
                          getOptionLabel={(option) => option.name + " - " + option.division}
                          options={this.state.allCompetitors}
                          loading={this.state.competitorsLoading}
                          renderInput={(params) => (
                            <TextField 
                              {...params}
                              label="Select competitor"
                              margin="dense"
                              id={`competitor_`+i}
                              variant="outlined"
                              value={c.username}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {this.state.competitorsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </TableCell> 
                      <TableCell align="center" padding="checkbox" size="medium" className={classes.plus}>
                         <Button onClick={() => {this.removeCompetitorSelection(i)}}><Delete/></Button>
                      </TableCell> 
                    </TableRow>
                  ))}
               </TableBody>
            </Table>
          </TableContainer>
        </Grid> 
        <Grid item xs={12} align="center">
          {this.state.addMoreCompetitors && <Button  align="center" onClick={() => {this.addCompetitorSelector()}}><Add />Add Competitor</Button>} 
        </Grid> 
      </>
    )
  }
  
  renderSelectedMatch(){
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="md">
        <Grid container spacing={3} disabled>      
          {this.header(classes)}
          {this.selectCompetition(classes)}
          {this.selectMatch(classes)}      
          {this.selectCompetitors(classes)}      
          <Grid item xs={12} align="center">
            <Button  id="submitScore" variant="contained" color="primary" size="large"  onClick={this.startMatch} disabled={this.state.startButtonDisabled}>Start Match</Button> 
          </Grid>
        </Grid>
      </Container>
    )
  }
  
  renderMatch() {
    switch(this.state.match){
      case "Practical":
        return <Practical competitors={this.state.competitors} competition={this.state.competition} scorer={this.props.user}/>
      case "Barricade":
        return <Barricade competitors={this.state.competitors} competition={this.state.competition} scorer={this.props.user}/>
      case "Mover":
        return <Mover competitors={this.state.competitors} competition={this.state.competition} scorer={this.props.user}/>
      case "Plates":
        return <Plates competitors={this.state.competitors} competition={this.state.competition} scorer={this.props.user}/>
      default:
        return null;
    }
  }

  renderStatus(classes){
    return (
      <>
        <Snackbar open={this.state.isShowStatus} autoHideDuration={5000} onClose={this.closeAlert}>
          <MuiAlert elevation={6} variant="filled" onClose={this.closeAlert} severity={this.state.statusType}>
            {this.state.statusMessage}
          </MuiAlert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={this.state.isNewCompetitionSaving}>
          <CircularProgress color="primary" />
        </Backdrop>
      </>
    )
  }
  
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.start ? this.renderMatch() : (this.renderSelectedMatch()) }
        {this.renderStatus(classes)}
      </>
    )
  }
}

export default withStyles(styles)(StartMatch);