const prod = {
  url: {
    API_URL: "https://derekbyrnes.com/cgi-bin/actionmatch",
  },
  ACCESS_TOKEN_NAME: "usertoken",
};
 
const test = {
  url: {
    API_URL: "https://derekbyrnes.com/cgi-bin/actionmatch",
  },
  ACCESS_TOKEN_NAME: "usertoken",
};

const dev = {
  url: {
    API_URL: "https://derekbyrnes.com/cgi-bin/actionmatch",
  },
  ACCESS_TOKEN_NAME: "usertoken",
};


export const config = (process.env.NODE_ENV === "build"? prod : (process.env.NODE_ENV === "test"? test : dev))