import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
//import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});


class Competition extends Component {
  
  constructor(props){
    super(props);
    this.state = {
        date: Date(),
        allCompetitions: [],
                
        competition: {title: ""}, 
        openCompetition: false,
      
        scores: [],
                
        competitionsLoading: false,
        leaderboardLoading: false,
    }
    this.getCompetitions = this.getCompetitions.bind(this);
    this.getLeaderboard = this.getLeaderboard.bind(this);
    this.updateSelectedCompetition = this.updateSelectedCompetition.bind(this);
  }
  
  componentDidMount(){
    this.getCompetitions();
  }
  
  async getCompetitions(){
    this.setState({competitionsLoading: true});
    try{
      let res = await axios.get(`/cgi-bin/getCompetitions.cgi`)
      this.setState({allCompetitions: res.data});
      console.log(res.data);
    }
    catch(error) {
          console.log(error);
    }      
    this.setState({competitionsLoading: false});
  }
  
  updateSelectedCompetition(competition){
      let comp = this.state.competition;
      comp = competition===null?({title: ""}):(competition);
      this.setState({competition: comp});
      console.log("Competition.updateSelectedCompetition: ", comp);
  }
  
  async getLeaderboard(){
    this.setState({leaderboardLoading: true});
    const uri = `/cgi-bin/getScores.cgi?`
                + "competitionid=" 
                + this.state.competition.id;
    try {
      console.log("Competition.getLeaderboard: ", uri);
      let res = await axios.get(uri);
      this.setState({scores: res.data});
      console.log("Competition.getLeaderboard: ", res.data);
    }
    catch(error){
      this.setState({scores: error});
      console.log("Competition.getLeaderboard: ", error);
    }      
    this.setState({leaderboardLoading: false});
  }
  
  header(){
    return (
      <Grid item xs={12}>
        <Typography variant="h5" color="primary" align="center">Competitions</Typography>
      </Grid>
    )
  }
  
  selectCompetition(){
    return (
      <Grid item xs={12}>
        <Autocomplete
          id="competition"
          open={this.state.openCompetition}
          onOpen=  {() => {this.setState({openCompetition: true});}}
          onClose= {() => {this.setState({openCompetition: false});}}
          onChange={(event, value) => {this.updateSelectedCompetition(value)}}
          getOptionSelected={(option, value) => option.title === value.title }
          getOptionLabel={(option) => option.date + ": " + option.title + ": Day " + option.day}
          options={this.state.allCompetitions}
          loading={this.state.competitionsLoading}
          renderInput={(params) => (
            <TextField 
              {...params}
              label="Select Competition"
              size="small"
              variant="filled"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {this.state.competitionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>     
    )
  }
  
  showCompetitionLeaderboard(){
    //if a competition hasnt been selected, don't return anything for rendering
    if(this.state.competition.title === ""){
      return null;
    }
    else if(this.state.scores.length === undefined){
      return (
        <Grid item xs align="center" wrap="nowrap">
          <Typography variant="h5" color="primary" align="center">{this.state.scores.message}</Typography>
          
          <Typography variant="body1" color="primary" align="center">{this.state.scores.stack}</Typography>
        </Grid>
      )
    }
    else if(this.state.scores.length >0){
      return (
        <Grid item xs={12} align="center">
          <TableContainer>
            <Table align='center' size='small' padding="none">
              <TableHead>
                  <TableRow>
                    <TableCell className='cell' padding="none" align="center">
                      <Typography variant="body1" color="secondary" align="center">Name</Typography>
                    </TableCell>
                    <TableCell className='cell' padding="none" align="center">
                      <Typography variant="body1" color="secondary" align="center">Nickname</Typography>
                    </TableCell>
                    <TableCell className='cell' padding="none" align="center">
                      <Typography variant="body1" color="secondary" align="center">Match</Typography>
                    </TableCell>
                    <TableCell className='cell' padding="none" align="center">
                      <Typography variant="body1" color="secondary" align="center">Score</Typography>
                    </TableCell>
                    <TableCell className='cell' padding="none" align="center">
                      <Typography variant="body1" color="secondary" align="center">{"X's"}</Typography>
                    </TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                {this.state.scores.map((score) => (
                  <TableRow>
                    <TableCell className='cell' padding="none" align="center">
                      <Typography key={score.matchtype} variant="body1" color="primary" align="center">{score.name}</Typography>
                    </TableCell>
                    <TableCell className='cell' padding="none" align="center">
                      <Typography key={score.matchtype} variant="body1" color="primary" align="center">{score.nickname}</Typography>
                    </TableCell>
                    <TableCell className='cell' padding="none" align="center">
                      <Typography key={score.matchtype} variant="body1" color="primary" align="center">{score.matchtype}</Typography>
                    </TableCell>
                    <TableCell className='cell' padding="none" align="center">
                      <Typography key={score.matchtype} variant="body1" color="primary" align="center">{score.score}</Typography>
                    </TableCell>
                    <TableCell className='cell' padding="none" align="center">
                      <Typography key={score.matchtype} variant="body1" color="primary" align="center">{score.bullseyes}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )
    }
    else {
      //return display element that renders the leaderboard for selected competition
      return (
        <Grid item xs={12} align="center">
          <Button  id="getLeaderboard" variant="contained" color="primary" size="large"  onClick={this.getLeaderboard}>View Competition Leaderboard</Button> 
        </Grid>
      )
    }
  }
  
  
  render () {
    const { classes } = this.props;            
    return (
      <Container maxWidth="md">
        <Grid container spacing={3} disabled>      
          {this.header()}
          {this.selectCompetition()}
          {this.showCompetitionLeaderboard()}
        </Grid>
        <Backdrop className={classes.backdrop} open={this.state.leaderboardLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    )
  }
}

export default withStyles(styles)(Competition);