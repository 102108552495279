import React, { Component } from 'react';

class Season extends Component {
  render() {
    return (
      <div className="card-container">
        <div className="card" id="leaderboard">
          <h2>Leaderboard</h2>
        </div>
      </div>    
    );
  }
}

export default Season;