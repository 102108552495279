import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOpen from '@material-ui/icons/LockOpen';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';



const styles = theme => ({
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class ResetPassword extends Component{
  render (){
    const {classes} = this.props;
    return (
      <Container maxWidth="xs">
        <Grid container spacing={1} align="center">      
          <Grid item xs={12}>
            <Avatar className={classes.avatar}>
              <LockOpen />
            </Avatar>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h4" variant="h4">
              Reset Password
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}>
              Reset Password
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs>
                <Link href="mailto:derekbyrnes@derekbyrnes.com?Subject=Action Match account issues &body=I am having issues logging in, please help! my name is... " variant="body2">
                  Having issues?
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="http://derekbyrnes.com">
              derekbyrnes.com
              </Link>{' '}
              {new Date().getFullYear()}
              {'.'}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default withStyles(styles)(ResetPassword);